<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"  @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <div class="col-12 lg:col-12 mb-0">
                <h3 class="mb-0"><span v-if="recordId && recordId > 0">Update Exam type and year</span><span v-else> Save Exam type and year</span> 
                    <!-- {{module_name}} -->
                </h3> 
            </div>
            <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="grid">
                                    <div class="col-12 lg:col-12 sm:col-12 md:col-12 mb-2 text-center"> 
                                        <h4>{{ form.name }}</h4>
                                    </div>
                                    <div class="col-12 lg:col-12 sm:col-12 md:col-12 mb-2">
                                        <label>Exam Type</label>
                                        <Dropdown v-model="form.exam_type" :options="examTypes" optionValue="id" optionLabel="name" placeholder="Select exam type"  class="full-width" />
                                        <HasError class="p-error" :form="form" field="exam_type" />
                                    </div> 
                                    <div class="col-12 lg:col-12 sm:col-12 md:col-12 mb-2">
                                        <label>Exam Year</label>
                                        <InputNumber id="year" v-model="form.exam_year"  class="full-width" :useGrouping="false" aria-describedby="number-error" :min="2023" :max="2050" :step="1" showButtons />
                                        <HasError class="p-error" :form="form" field="exam_year" />
                                        
                                    </div> 
                                </div>
                            </div>  
                        </div> 
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                
                                <Button type="submit" :label="(recordId && recordId > 0) ? 'Update' : 'Save'" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2 mb-2" v-if="!(recordId && recordId > 0)"/>
                                <Button type="submit" :label="(recordId && recordId > 0) ? 'Update and Close' : 'Save and Close'"  :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2 mb-2"/>
                                <!-- <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
        <ConfirmDialog group="dialog" />
    </BlockUI>
    
</template>
<style scoped>
    .active-room{
        background-color: aquamarine !important;
    }
</style>
<script> 
export default {
    data() {
			return {  
                form: new this.Form({  
                    name: '',
                    exam_type: '', 
                    exam_year: '', 
                }),   
                module_name:'Exam Form',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                selectedClass: [],
                examTypes: [
                    { name: 'PROF ANNUAL', id: 'ANNUAL' },
                    { name: 'PROF SUPPLEMENTARY', id: 'SUPPLEMENTARY' }
                  
                ]

                
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedData:{
                type : String,
            }
        },
		created() {  
           
		},
        computed: { 
    
          
            
        },
		mounted() {  
            this.resetForm();
            this.getAllRequestData();  
            this.formVisible = this.showForm; 
            this.recId = this.recordId; 
            this.selectedData ? this.form.fill(JSON.parse(this.selectedData)) : null;
            this.getAllClasses();
		},
		methods: { 
            getAllClasses() {
            var Vthis = this;
            // this.loading = true;
            this.axios
                .post('api/get-class')
                .then((response) => {
                    this.selectedClass = response.data;
                    // this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
            },
            getAllRequestData(){   
                this.$axios.all([])   
                .then(() => {  
                    // this.viewRecord();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
           
            resetForm(){
                this.form.reset();
            },
            async save(){ 
                var Vthis = this; 
                this.isSaving = true;
                this.form.id = this.recId;
                this.autoFormateDates();
                var url = Vthis.$baseurl+'api/save-exam';
                if(this.recId > 0){ url = Vthis.$baseurl+'api/update-exam'; }
                await this.form.post(url)
                .then(() => { 
                    if(this.saveAndClose){   
                        this.saveAndClose = false;
                        this.closeForm();
                    }   
                    Vthis.showMessage('Saved successfully','success',); 
                })
                .catch((error) => {   
                    let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {
                    this.isSaving = false; 
                    this.getAllRequestData();
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
            getTrueFalse(status) {
            switch (status) {
                case 1:
                    return 'success';

                case 0:
                    return 'danger';

                default:
                    return null;
                }
            },
            autoFormateDates()
            {
                if (this.form.from_date) {
                const fromDate = new Date(this.form.from_date);
                fromDate.setDate(fromDate.getDate() + 1);
                this.form.from_date = fromDate.toISOString();
                }

                // Add one day to this.form.to_date
                if (this.form.to_date) {
                const toDate = new Date(this.form.to_date);
                toDate.setDate(toDate.getDate() + 1);
                this.form.to_date = toDate.toISOString();
                }
            },
            updateName() {
            // Check if both exam_type and exam_year are not null
            if (this.form.exam_type && this.form.exam_year) {
                this.form.name = `PROF ${this.form.exam_type} ${this.form.exam_year}`;
            } else {
                this.form.name = ''; // Set to empty if either is null
            }
            },
           
            
		},
        watch : {
            'form.exam_type': function(newExamType, oldExamType) {
            this.updateName();
            },
            'form.exam_year': function(newExamYear, oldExamYear) {
            this.updateName();
            }
             
        }
}
</script>
 
